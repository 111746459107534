var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sx-editor-table" },
    [
      _c("h4", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "sx-editor-actions" },
        [
          _c("v-text-field", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchVisible,
                expression: "searchVisible",
              },
            ],
            attrs: {
              "append-icon": "mdi-magnify",
              placeholder: "Suche...",
              outlined: "",
              dense: "",
            },
            on: { input: _vm.onSearch },
          }),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editorId == "customer",
                  expression: "editorId == 'customer'",
                },
              ],
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.openfileUploadDialog()
                },
              },
            },
            [_vm._v(" Excel-Import ")]
          ),
          !_vm.disableNew
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.openEditorDialog(_vm.editorId)
                    },
                  },
                },
                [
                  _vm._v(" Neu "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-plus"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("ag-grid-vue", {
        staticClass: "ag-theme-alpine ag-table",
        attrs: {
          rowData: _vm.filteredRows,
          columnDefs: _vm.columns,
          gridOptions: _vm.gridOptions,
          defaultColDef: _vm.columnConfiguration,
          loadingOverlayComponentFramework: _vm.loadingOverlayComponent,
          enableCellChangeFlash: _vm.enableCellChangeFlash,
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _c(
        "sx-dialog",
        { ref: "editorDialog", attrs: { title: _vm.dialogTitle } },
        [
          _vm._t("dialogContent"),
          _c(
            "template",
            { slot: "actions" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    color: "primary",
                    disabled: !_vm.isValidPhoneNumber,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.saveEditorDialog.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Speichern ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("sx-file-upload", {
        ref: "fileUploadDialog",
        attrs: { title: "Excel-Import", accept: "excel", width: 700 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }