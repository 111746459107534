<template>
    <div class="sx-editor-table">
        <h4>{{ title }}</h4>
        <div class="sx-editor-actions">
            <v-text-field append-icon="mdi-magnify" placeholder="Suche..." v-show="searchVisible" @input="onSearch"
                outlined dense></v-text-field>
            <v-btn color="primary" @click="openfileUploadDialog()" v-show="editorId == 'customer'">
                Excel-Import
            </v-btn>

            <v-btn color="primary" v-if="!disableNew" @click="openEditorDialog(editorId)">
                Neu
                <v-icon right dark>mdi-plus</v-icon>
            </v-btn>
        </div>
        <ag-grid-vue :rowData="filteredRows" :columnDefs="columns" :gridOptions="gridOptions"
            class="ag-theme-alpine ag-table" :defaultColDef="columnConfiguration" @grid-ready="onGridReady"
            :loadingOverlayComponentFramework="loadingOverlayComponent"
            :enableCellChangeFlash="enableCellChangeFlash" />
        <sx-dialog ref="editorDialog" :title="dialogTitle">
            <slot name="dialogContent" />
            <template slot="actions">
                <v-spacer />
                <v-btn small color="primary" @click.native="saveEditorDialog" :disabled="!isValidPhoneNumber">Speichern
                </v-btn>
            </template>
        </sx-dialog>

        <sx-file-upload :title="'Excel-Import'" :accept="'excel'" ref="fileUploadDialog" :width="700">
        </sx-file-upload>
    </div>
</template>

<script>
import SxEditorTableCell from "./SxEditorTableCell.vue";
import agGridLocaleDe from "../../config/ag-grid-locale.de.config";
import SxLoadingCellRenderer from "./SxLoadingCellRenderer";
import SxFileUpload from "./SxFileUpload.vue";

export default {
    name: "sx-editor-table",
    components: {
        /* eslint-disable vue/no-unused-components */
        SxEditorTableCell,
        SxLoadingCellRenderer,
        SxFileUpload
    },
    props: {
        searchVisible: {
            type: Boolean,
            default: false
        },
        searchCallback: {
            type: Function,
            default: null
        },
        columns: {
            type: Array,
            default: () => [],
        },
        rows: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Object,
            default: () => ({
                pagination: true,
                paginationPageSize: 10,
            }),
        },
        enableCellChangeFlash: {
            type: Boolean,
            default: true,
        },
        columnConfiguration: {
            type: Object,
            default: () => ({
                minWidth: 5,
                filter: true,
                sortable: true,
                resizable: true,
                enableRowGroup: true,
            }),
        },
        title: {
            type: String,
        },
        dialogTitle: {
            type: String,
        },
        editorId: {
            type: String,
        },
        onAction: {
            type: Function,
        },
        onSave: {
            type: Function,
        },
        disableNew: {
            type: Boolean,
            default: false,
        },

    },
    data() {
        return {
            searchText: '',
            filteredRows: [],
            currentEditorId: null,
            isValidPhoneNumber: {
                type: Boolean,
                default: true
            },
            loadingOverlayComponent: "SxLoadingCellRenderer",
        };
    },
    computed: {
        gridOptions() {
            let gridOptions = this.options;
            gridOptions.columnDefs = this.columns;
            gridOptions.localeText = agGridLocaleDe;
            gridOptions.suppressLoadingOverlay = true;
            return gridOptions;
        }
    },
    created() {
        // attach edit and delete handlers of this editorId
        this.$eventBus.$on("on-" + this.editorId + "-table-cell-edit", this.onEdit);
        this.$eventBus.$on("on-" + this.editorId + "-table-cell-delete", this.onDelete);
    },
    watch: {
        rows: function (v) {
            this.updateRows(v)
        }
    },
    beforeDestroy() {
        // unattach edit and delete handlers of this editorId
        this.$eventBus.$off("on-" + this.editorId + "-table-cell-edit", this.onEdit);
        this.$eventBus.$off("on-" + this.editorId + "-table-cell-delete", this.onDelete);
    },
    methods: {
        onEdit(params) {
            this.openEditorDialog(
                params.colDef.cellRendererParams.editorId,
                params
            );
        },
        onDelete(params) {
            if (this.onAction != null)
                this.onAction(
                    params.colDef.cellRendererParams.editorId,
                    "delete",
                    params
                );
        },
        updateRows(rows) {
            if (this.searchCallback == null)
                this.filteredRows = rows
            if (this.searchText == null)
                this.filteredRows = rows
            let newCollection = [...rows]
            if (this.searchText.length > 0)
                newCollection = newCollection.filter(u => JSON.stringify(u).toLowerCase().includes(this.searchText.toLowerCase()))
            this.filteredRows = newCollection
        },

        openfileUploadDialog() {
            this.$refs["fileUploadDialog"].show();
        },

        openEditorDialog(editorId, params) {
            this.isValidPhoneNumber = true;
            if (this.editorId == editorId) {
                this.currentEditorId = editorId;
                this.$refs["editorDialog"].show();
                if (this.onAction != null)
                    if (params == null)
                        this.onAction(this.currentEditorId, "new");
                    else this.onAction(this.currentEditorId, "edit", params);
            }
        },
        saveEditorDialog() {
            if (this.onAction != null)
                this.onAction(this.currentEditorId, "save");
        },
        closeEditorDialog() {
            this.$refs["editorDialog"].close();
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            this.gridApi.showLoadingOverlay();
        },
        onSearch(value) {
            if (this.searchCallback != null)
                this.searchCallback(value)
            else {
                this.searchText = value
                this.updateRows(this.rows)
            }
        }
    },
};
</script>

<style lang="sass">
.sx-editor-table
    position: relative
    h4
        height: 25px
        margin-bottom: 14px
        margin-top: 10px

.ag-table
    height: 32.5rem !important
    min-height: 25.5rem !important

.sx-editor-actions
    position: absolute
    z-index: 10
    top: -8px
    right: 0px
    display: flex
    & > *
        margin-left: 5px
    .v-input
        margin-top: -0.1rem

tbody td
    font-size: 0.8rem !important
</style>