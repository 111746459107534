<template>
  <div class="sx-editor-table-cell">
      <sx-icon 
      icon="mdi-pencil" 
      v-if="!hideEdit()"
      @click.native="onEdit" />
      <sx-icon 
      icon="mdi-delete" 
      v-if="!hideDelete()"
      @click.native="onDelete" />
  </div>
</template>

<script>
export default {
    name: 'sx-editor-table-cell',
    props: [],
    methods: {
        onEdit() {
            // notify SxEditorTable or other components about an edit request on this editorId
            this.$eventBus.$emit('on-' + this.params.editorId + '-table-cell-edit', this.params)
        },
        onDelete() {
            this.$eventBus.$emit('on-confirm', 'Löschen bestätigen', 'Wollen Sie den Datensatz wirklich löschen?', () => {
                // notify SxEditorTable or other components about a delete request on this editorId
                this.$eventBus.$emit('on-' + this.params.editorId + '-table-cell-delete', this.params)
            })
        },
        hideDelete() {
            if(this.params.hideDelete != null)
                return this.params.hideDelete(this.params.data)
            return false
        },
        hideEdit() {
            if(this.params.hideEdit != null)
                return this.params.hideEdit(this.params.data)
            return false
        }
    }
}
</script>

<style lang="sass">

.sx-editor-table-cell
    display: flex
    padding: 10px
    i
        margin-right: 5px

</style>