var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sx-editor-table-cell" },
    [
      !_vm.hideEdit()
        ? _c("sx-icon", {
            attrs: { icon: "mdi-pencil" },
            nativeOn: {
              click: function ($event) {
                return _vm.onEdit.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      !_vm.hideDelete()
        ? _c("sx-icon", {
            attrs: { icon: "mdi-delete" },
            nativeOn: {
              click: function ($event) {
                return _vm.onDelete.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }